import React from 'react';

const PostFooter = () => (
    <div
        style={{
            backgroundColor: '#292b31',
            height: 100
        }}
    >

    </div>
);

PostFooter.propTypes = {};

PostFooter.defaultProps = {};

export default PostFooter;